<template>
    <div class="mt-1 flex flex-col lg:flex-row rounded-md shadow-md w-full mb-4">
        <div class="relative flex w-full focus-within:z-10 focus-within:z-10">
            <geocoder-input></geocoder-input>
        </div>
        <!--    <button class="-ml-px relative space-x-2 px-4 py-2 text-lg font-medium text-white-->
        <!--    bg-green-400 hover:bg-green-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 rounded-b-md lg:rounded-b-none lg:rounded-r-md text-center lg:text-left">-->
        <!--      <span>Check your coverage</span>-->
        <!--    </button>-->
    </div>
</template>

<script>
import GeocoderInput from "@/components/GeocoderInput.vue";

export default {
    name: 'CoverageChecker',
    components: {GeocoderInput},
}
</script>
