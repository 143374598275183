<template>
    <div>
        <div v-if="this.coverageCheckResult.value?.in_planned_coverage" class="prose mb-4 space-y-2">
            <p> We’ve detected that a tower is currently being built in your area. </p>
            <p> Fill out your details in the form below and we'll get in touch when we're ready to get you connected to Lightwire's reliable rural broadband.</p>
        </div>
        <div v-if="!this.coverageCheckResult.value?.in_planned_coverage" class="prose mb-4 space-y-2">
            <p>Unfortunately it looks like we do not have coverage at your address.</p>
            <p> The good news is that our network is constantly expanding and more households come within coverage of
                our towers every week.</p>
            <p>If you would like to add your details to the form below, we will get in touch if/when your house is able to get high speed rural broadband from Lightwire.</p>
        </div>
        <div class="grid grid-cols-1 space-y-2 text-left mb-4 max-w-sm">
            <div>
                <label class="block text-sm font-medium text-gray-100" for="name">Full Name <span
                    class="text-red-500">*</span></label>
                <div class="">
                    <input id="name" v-model='name' autocomplete="name"
                           class="text-black block w-full shadow-sm sm:text-sm focus:ring-blue-500 focus:border-blue-500 border-gray-300 rounded-md"
                           name="name"
                           type="text">
                    <div v-if="errors['name']" class="text-red-300 mt-1">{{ errors['name'][0] }}</div>

                </div>
            </div>
            <div>
                <label class="block text-sm font-medium text-gray-100" for="phone">Phone Number <span
                    class="text-red-500">*</span></label>
                <div class="">
                    <input id="phone" v-model='phone' autocomplete="phone"
                           class="text-black block w-full shadow-sm sm:text-sm focus:ring-blue-500 focus:border-blue-500 border-gray-300 rounded-md"
                           name="phone"
                           type="text">
                    <div v-if="errors['phone']" class="text-red-300 mt-1">{{ errors['phone'][0] }}</div>
                </div>
            </div>
            <div>
                <label class="block text-sm font-medium text-gray-100" for="email">Email <span
                    class="text-red-500">*</span></label>
                <div class="">
                    <input id="email" v-model='email' autocomplete="email"
                           class="text-black block w-full shadow-sm sm:text-sm focus:ring-blue-500 focus:border-blue-500 border-gray-300 rounded-md"
                           name="email"
                           type="email">
                    <div v-if="errors['email']" class="text-red-300 mt-1">{{ errors['email'][0] }}</div>
                </div>
            </div>
            <div class="">
                <button id="join-wait-list-button"
                        class="bg-[#F8AD3C] text-white font-semibold px-3 py-2 w-full mt-2 rounded-lg shadow"
                        type="button" @click="submit"> Join
                    Wait List
                </button>
            </div>
            <loading-spinner v-if="loading"></loading-spinner>
        </div>
        <p>If you need more information, please call us on <a class="text-pink-500" href='tel:0800121314'>0800 12 13
            14</a>. If you're in a jam, we
            can double check and see if any alternative solutions may be available for you - now or in the future.</p>
    </div>
</template>

<script>
import {coverageCheckResult, geocodeResult} from "@/state.ts";
import axios from "axios";
import LoadingSpinner from "@/components/LoadingSpinner";

export default {
    name: 'JoinWaitList',
    components: {LoadingSpinner},
    props: ['address'],

    data() {
        return {
            name: "",
            phone: "",
            email: "",
            errors: {},
            loading: false
        }
    },


    setup() {
        return {
            coverageCheckResult, geocodeResult
        }
    },

    methods: {
        submit() {
            this.loading = true;
            this.errors = {};

            let celium = axios.create();
            celium.defaults.baseURL = process.env.VUE_APP_CELIUM_BASE_URL;
            celium.post('rural/waiters', {
                name: this.name,
                phone: this.phone,
                email: this.email,
                longitude: this.geocodeResult.value.geometry.coordinates[0],
                latitude: this.geocodeResult.value.geometry.coordinates[1],
                address: this.geocodeResult.value.place_name,
                sector_id: this.getPlannedSectorId(),
            }).then(() => {
                this.loading = false;
                window.location.href = "https://lightwire.co.nz/thanks-for-joining-wait-list";
            }).catch((reason) => {
                this.loading = false;
                this.errors = reason.response.data.errors
            })
        },
        getPlannedSectorId() {
            //find the first sector with service_id 3 (finalised planned coverage)
            return this.coverageCheckResult.value?.in_planned_coverage ? this.coverageCheckResult.value.lightwire.rural.find(tower => {
                return tower.sectors.find(sector => {
                    if (sector.service_id === 3) {
                        return sector.id;
                    }
                })
            }).sectors[0].id : null;
        }
    }
}
</script>
