<template>
  <div>
    <CoverageChecker/>
    <CoverageResult/>
  </div>
</template>

<script>
import CoverageChecker from '@/components/CoverageChecker.vue'
import CoverageResult from "@/components/CoverageResult.vue";
import doesCoverageCheck from '@/actions/doesCoverageCheck.ts'
import redirectsOnInCoverage from "@/actions/redirectsOnInCoverage.ts";
import addsReferrerPromocode from '@/actions/addsReferrerPromocode.ts'

export default {
  name: 'App',
  components: {
    CoverageResult,
    CoverageChecker
  },
  setup() {
    doesCoverageCheck(),
    redirectsOnInCoverage()
  },
  mounted() {
    addsReferrerPromocode()
  }
}
</script>
