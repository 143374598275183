<template>
  <div v-if="coverageCheckResult.value?.in_coverage === false && coverageCheckResult.value?.linz.exclusion_zone === null">
      <join-wait-list></join-wait-list>
  </div>
  <div v-if="coverageCheckResult.value?.in_coverage === false && coverageCheckResult.value?.linz.exclusion_zone !== null">
      <p>Unfortunately it looks like we are unable to provide coverage at your address.</p>
      <br>
      <p>If you would like to call our helpdesk on <a href='tel:0800121314' class="text-pink-500">0800 12 13 14</a> we can manually check and see if any solutions may be available for you.</p>
  </div>
  <div v-if="coverageCheckResult.error === true">
    <p>Unfortunately it looks like we aren't able to check coverage at the moment.</p>
    <br>
    <p>If you would like to call our helpdesk on <a href='tel:0800121314' class="text-pink-500">0800 12 13 14</a> we can manually check and see if any solutions may be available for you.</p>
  </div>
  <div class="flex justify-center">
    <loading-spinner/>
  </div>
</template>

<script>
import {coverageCheckResult} from "@/state.ts";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import JoinWaitList from "@/components/JoinWaitList.vue";

export default {
  name: 'CoverageResult',
  components: {JoinWaitList, LoadingSpinner},
  setup() {
    return {
      coverageCheckResult
    }
  }
}
</script>
