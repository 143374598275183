// @ts-ignore
import {coverageCheckResult} from '../state.ts'
import { watch } from 'vue'


export default function redirectsOnInCoverage() {
    watch(coverageCheckResult, (newValue) => {
        if(newValue.value.in_coverage) {
            let network = newValue.value.ont_location ? 'fibre' : 'core';
            window.top.location.href = `${process.env.VUE_APP_SIGNUP_URL + newValue.value.jwt}?network=${network}`;
        }
    })

    return {};
}
