<template>
  <div id="geocoder" class="w-full text-left"></div>
</template>

<script>
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder'
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css'
import {geocodeResult,loading,coverageCheckResult} from "../state.ts";

export default {
  name: 'GeocoderInput',
  mounted() {
    this.geocoder.addTo('#geocoder')
  },
  setup() {
    //https://github.com/mapbox/mapbox-gl-geocoder/blob/master/API.md
    const geocoder = new MapboxGeocoder({
      accessToken: 'pk.eyJ1Ijoic3RlcGgtbHciLCJhIjoiY2sydjltYnhjMDJ3MjNjbHp0NHp0NDhzZSJ9.750eB30DMqZB6f8cgiZg3Q',
      types: 'address',
      placeholder: 'Enter Your Address',
      bbox: [174.217546, -38.965732, 177.403581, -36.586777], // Boundary for Lightwire Rural
    })
    geocoder.on(loading, () => loading.value = true);
    geocoder.on('result', (geocoder) => geocodeResult.value = geocoder.result);
    geocoder.on('clear', () => coverageCheckResult.value = {});

    return {
      geocoder
    }
  }
}
</script>

<style>
.mapboxgl-ctrl-geocoder--input {
  padding-left: 2.5rem !important;
  padding-left: 3rem !important;
}

.mapboxgl-ctrl-geocoder {
  min-width: 100%;
  box-shadow: none;
}

.mapboxgl-ctrl-geocoder--input {
  @apply text-lg w-full h-12 block pl-10 border-gray-300 bg-gray-100 whitespace-nowrap pl-12 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 !important
}

.mapboxgl-ctrl-geocoder--icon-search, .mapboxgl-ctrl-geocoder--icon-loading {
  @apply w-8 h-8 !important
}

.mapboxgl-ctrl-geocoder--icon-loading {
  @apply w-8 h-8 mt-0.5 !important
}
.mapboxgl-ctrl-geocoder--button {
  @apply bg-transparent !important
}
.mapboxgl-ctrl-geocoder--icon-close {
  @apply w-5 h-5 mt-1.5 !important
}
</style>
